import React from 'react'
import htmr from 'htmr'
import { 
  DesktopOnly,
  Layout,
  MobileOnly,
  SEO,
  SmartSolution 
} from 'components'
import { 
  Wrapper, 
  Title, 
  CreatorAndCreatedAt, 
  BaseTLDR, 
  Thumbnail,
  SmartSolutionWrapper,
  BodyWrapper,
  WrapperShare 
} from './styles'
import LinkedInIcon from './media/linkedin.png'
import FbIcon from './media/facebook.png'
import WaIcon from './media/whatsapp.png'
import TwitterIcon from './media/twitter.png'
import { FormattedMessage } from 'utils/intl'

const BlogDetail = ({ pageContext: { article } }) => {
  const TLDR = htmr(article.description)
  const bodyArticle = htmr(article.body)

  const shareLinkedInLink = `https://www.linkedin.com/sharing/share-offsite/?url=${process.env.GATSBY_HOSTNAME}/articles/${article.slug }`
  const shareFbLink = `https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_HOSTNAME}/articles/${article.slug }&t=${ article.title }`
  const shareWaLinkWeb = `https://web.whatsapp.com/send?text=${process.env.GATSBY_HOSTNAME}/articles/${article.slug }`
  const shareWaLinkApp = `whatsapp://send?text=${process.env.GATSBY_HOSTNAME}/articles/${article.slug }`
  const shareTwitterLink = `http://twitter.com/intent/tweet?text=${ article.title } - ${process.env.GATSBY_HOSTNAME}/articles/${article.slug }`

  return (
    <Layout>
      <SEO title={ article.title } description={ article.description } image={ article.thumbnailPhoto } />
      <Wrapper>
        <Title>{ article.title }</Title>
        <CreatorAndCreatedAt>{ article.author }</CreatorAndCreatedAt>
        <BaseTLDR>{ TLDR }</BaseTLDR>
        <Thumbnail src={ article.thumbnailPhoto } alt={ `${article.title} image` } />
        <BodyWrapper>
          { bodyArticle }
        </BodyWrapper>
      </Wrapper>
      <WrapperShare>

        <p>
          <FormattedMessage id="5beef4d69" />
        </p>
        <DesktopOnly>
          <a 
            id={ `share-article-wa-${article.slug}` }
            href={ shareWaLinkWeb } 
            target='blank' 
            rel='noopener'
          >
            <img
              src={ WaIcon }
              alt='whatsapp'
            />
          </a>
        </DesktopOnly>

        <MobileOnly>
          <a 
            id={ `share-article-wa-${article.slug}` }
            href={ shareWaLinkApp } 
            target='blank' 
            rel='noopener'
          >
            <img
              src={ WaIcon }
              alt='whatsapp'
            />
          </a>
        </MobileOnly>

        <a 
          id={ `share-article-linkedin-${article.slug}` }
          href={ shareLinkedInLink } 
          target='blank' 
          rel='noopener'
        >
          <img
            src={ LinkedInIcon }
            alt='linkedin'
          />
        </a>

        <a 
          id={ `share-article-fb-${article.slug}` }
          href={ shareFbLink } 
          target='blank' 
          rel='noopener'
        >
          <img
            src={ FbIcon }
            alt='fb'
          />
        </a>

        <a 
          id={ `share-article-twitter-${article.slug}` }
          href={ shareTwitterLink } 
          target='blank' 
          rel='noopener'
        >
          <img
            src={ TwitterIcon }
            alt='twitter'
          />
        </a>
      </WrapperShare>
      <SmartSolutionWrapper>
        <SmartSolution />
      </SmartSolutionWrapper>
    </Layout>
  )
}

export default BlogDetail
