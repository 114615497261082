import styled from 'styled-components'
import { coolGrey, darkBlueGrey, mykonosBlue } from 'components/baseColor'
import { 
  IS_FRAME_MOBILE,
  IS_FRAME_TABLET 
} from 'utils'

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 800px;
  padding: 30px 0;
  position: relative;

  ${IS_FRAME_TABLET} {
    width: unset;
    padding: 0 1.5rem;
  }

  ${IS_FRAME_MOBILE} {
    width: unset;
    padding: 0 1rem;
  }
`

export const Title = styled.h1`
  font-size: 2.3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
  margin: 0;

  ${IS_FRAME_TABLET} {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  ${IS_FRAME_MOBILE} {
    font-size: 1.3rem;
    margin-top: 0.75rem;
  }
`

export const CreatorAndCreatedAt = styled.p`
  opacity: 0.5;
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    font-size: 0.9rem;
  }
`

export const BaseTLDR = styled.p`
  font-size: 0.95rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.2px;
  color: ${darkBlueGrey};
  margin: 0;

  ${IS_FRAME_MOBILE} {
    font-size: 0.875rem;
  }
`

export const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 18px;

  ${IS_FRAME_MOBILE} {
    height: 11rem;
    object-fit: contain;
  }
`

export const SmartSolutionWrapper = styled.div`
  margin: 3rem 5rem 2rem;

  ${IS_FRAME_TABLET} {
    margin: 2rem 8rem 0;
  }

  ${IS_FRAME_MOBILE} {
    padding-left: 10px;
    padding-right: 10px;
    margin: 2rem 0 0;
  }
`

export const BodyWrapper = styled.div`

  img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
    margin: 2rem auto;
    display: block;
  
    ${IS_FRAME_MOBILE} {
      height: 11rem;
      object-fit: contain;
      margin: 1rem auto;
    }
  }

  p, span {
    font-family: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    font-size: 0.95rem !important;
    line-height: 1.6 !important;
    color: ${darkBlueGrey} !important;
    letter-spacing: 0.2px !important;
  }

  a {
    text-decoration: none;
    span {
      color: ${mykonosBlue} !important;
    }
  }

  ${IS_FRAME_MOBILE} {
    p, span {
      font-size: 0.875rem !important;
    }
  }
`

export const WrapperShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    color: ${coolGrey};
    font-weight: 600;
    opacity: 0.7;
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  > div {
    padding: 0 0.5rem;
  }

  > a {
    padding: 0 0.5rem;
  }

  ${IS_FRAME_MOBILE} {
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0 1rem;

    > p {
      flex: 0 0 100%;
      text-align: right;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }

    > a {
      padding: 0 0.3rem;
    }
  }
`
